import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import utils from './utils'             //获取url参数

Vue.prototype.$utils = utils            //注册全局方法


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

Vue.use(axios);
