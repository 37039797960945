<template>
  <div class="home">
    <div class="title">
      <img src="../assets/xtlogo.png" alt="" class="logo">
      <span class="title_text">翔天农业追溯系统</span>
    </div>
    <div class="product-img_box">

      <img :src="detail.showImgUrl" alt="" class="product-img">
      <span style="color: red; font-size: 12px;">*图片仅供参考，请以实物为准</span>
    </div>
    <div class="product-message_box">
      <div style="line-height: 20px; display: flex;">
        <div style="height: 20px; width: 5px; background-color: #18CF8A;"></div>
        <span style="font-size: 20px; color: #fff; font-weight: 600; margin-left: 20px;">产品信息</span>
      </div>

      <div class="message_list">
        <div class="dot"></div>
        <span class="message_title">产品名称:</span>
        <span class="message_text">{{detail.varieties}}</span>
      </div>

      <div class="message_list">
        <div class="dot"></div>
        <span class="message_title">净含量:</span>
        <span class="message_text">{{detail.netContent}}kg</span>
      </div>

      <div class="message_list">
        <div class="dot"></div>
        <span class="message_title">产地:</span>
        <span class="message_text">{{detail.placeOfOrigin}}</span>
      </div>

      <div class="message_list">
        <div class="dot"></div>
        <span class="message_title">园区-温室:</span>
        <span class="message_text">{{detail.placeOfOrigin}}-{{detail.placeOfOrigin}}</span>
      </div>

      <div class="message_list">
        <div class="dot"></div>
        <span class="message_title">出厂日期:</span>
        <span class="message_text">{{detail.endTime}}</span>
      </div>

      <div class="message_table">
        <div class="table_title">
          <div class="dot"></div>
          <span class="message_title">施肥记录:</span>
        </div>
        <div style="text-align: center;">
          <div class="table" v-for="(item, index) in detail.fertilizations" v-if="index < 3" :key="index">
            <span class="message_text">{{item.createTime}}</span>
            <!-- <span class="message_text">{{item.fertilizationName}}</span> -->
            <span class="message_text">{{item.fertilizationName}}</span>
          </div>
        </div>
      </div>

      <div class="message_table">
        <div class="table_title">
          <div class="dot"></div>
          <span class="message_title">打药记录:</span>
        </div>
        <div style="text-align: center;">
          <div class="table" v-for="(item, index) in detail.sprayInsecticids" v-if="index < 3" :key="index">
            <span class="message_text">{{item.createTime}}</span>
            <!-- <span class="message_text">{{item.drugName}}</span> -->
            <span class="message_text">{{item.drugName}}</span>
          </div>
        </div>
      </div>

      <div class="message_list">
        <div class="dot"></div>
        <span class="message_title">仓储信息:</span>
        <span class="message_text">{{detail.startTime}} - {{detail.endTime}}</span>
      </div>

      <div class="message_list">
        <div class="dot"></div>
        <span class="message_title">物流信息:</span>
        <div style="text-align: center;">
          <div class="table2" v-for="(item, index) in detail.transportations" :key="index">
            <span class="message_text">{{item.departPlace}}-{{item.arrivePlace}}</span>
            <span class="message_text">{{item.departTime}} - {{item.arriveTime}}</span>
          </div>
        </div>
      </div>

      <div class="message_list">
        <div class="dot"></div>
        <span class="message_title">保质期:</span>
        <span class="message_text">{{detail.shelfLife}}天</span>
      </div>

      <div class="message_list">
        <div class="dot"></div>
        <span class="message_title">产品批次号:</span>
        <span class="message_text">{{detail.productNo}}</span>
      </div>

      <div class="message_list">
        <div class="dot"></div>
        <span class="message_title">质检证书:</span>
        <img v-if="imgUrl!=='--'" :src="imgUrl" alt="" class="qualityInspections-img">
        <div v-else class="message_text">--</div>
      </div>

      <div class="btn">该标签已认证为防伪标签</div>
      <!-- <div class="btn">qu:{{$route.query.fieldBatches}}</div> -->
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import axios from 'axios';
  export default {
    name: 'Home',
    components: {
      // HelloWorld
    },
    data() {
      return {
        detail: {},
        imgUrl: "",
        productNo: ""
      }
    },
    created() {
      // this.productNo = this.$route.query.fieldBatches
      let fieldBatches = this.$utils.getUrlKey("fieldBatches")
      // this.productNo = fieldBatches
      // console.log("1111");
      this.getTest(fieldBatches)
    },
    methods: {

      getTest(fieldBatches) {
        this.$nextTick(() => {
          axios({
            url: 'https://wit.xtiot.net/api/monitor/productiontraceability/h5',
            params: {
              productNo: fieldBatches
              // productNo: "TRY20220419163026"
            }
          }).then((res) => {
            const data = res.data.data
            console.log(data);

            if (typeof data != "undefined") {
              this.detail = data
              this.sprayInsecticids = data.sprayInsecticids
              this.fertilizations = data.fertilizations
              data.qualityInspections.length > 0 ? this.imgUrl = data.qualityInspections[0].photo : this
                .imgUrl = "--"
              // console.log(this.imgUrl);
            } else {
              console.log("未获取到数据")
            }

          });
        });
      },
    },
  }
</script>

<style lang="css" scoped>
  /* @import "./../api/region.js" */

  .home {
    background-image: url(../assets/bgimg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-attachment: fixed;
    -webkit-background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .logo {
    width: 30px;
    height: 30px;
  }

  .title {
    width: 85%;
    display: flex;
    margin: 20px 0;
    line-height: 30px;
  }

  .title_text {
    font-size: 25px;
    font-weight: bold;
    color: #fff;
    margin-left: 20px;
  }

  .product-img_box {
    position: relative;
    width: 85%;
    height: 0;
    padding-top: 85%;
  }

  .product-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .product-message_box {
    width: 80%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 20px 20px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .message_list {
    margin-top: 20px;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    width: 100%;
    border-bottom: #c2c2c2 solid 1px;
    line-height: 30px;

  }

  .message_table {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: #c2c2c2 solid 1px;
    line-height: 30px;
  }

  .dot {
    width: 5px;
    height: 5px;
    background-color: #18CF8A;
  }

  .message_title {
    color: #c2c2c2;
    margin: 0 10px;
    font-size: 16xp;
  }

  .message_text {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    margin-right: 5px;

  }

  .table_title {
    display: flex;
    align-items: center;
  }

  .table {
    display: flex;
    font-size: 12px;

  }

  .table2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;

  }

  .qualityInspections-img {
    width: 130px;
    height: 180px;
  }

  .btn {
    width: 100%;
    height: 50px;
    background-color: #18CF8A;

    border-radius: 5px;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    line-height: 50px;
    margin: 20px 0;
    text-align: center;
  }
</style>